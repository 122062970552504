import { createContext, useState } from 'react';
import React from 'react'

export const MyContext = createContext();

export function ContextProvider({ children }) {

    const [user, setUser] = useState({})
    const [larguraMenu , setLarguraMenu] = useState(210)

    return (
        <MyContext.Provider value={{ user, setUser , larguraMenu , setLarguraMenu }}>
            {children}
        </MyContext.Provider>
    )
}