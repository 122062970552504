
//import Login from './pages/public/login/Login';
//import Painel from './pages/private/painel/Painel'
import { ContextProvider } from './context/MyContext';
import Rotas from './routes/Rotas';
import { AuthProvider } from './context/AuthContext';

function App() {
  return (
    <AuthProvider>
      <ContextProvider>
          <Rotas />
      </ContextProvider>
    </AuthProvider>
  );
}

export default App;
