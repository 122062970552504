import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { NavLink } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import '@fontsource/roboto/500.css';

const Gear = () => {

    const [anchorElUser, setAnchorElUser] = React.useState(null);
    
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <>
            <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <SettingsIcon fontSize='medium' sx={{ color: 'white' }} />
                    </IconButton>
                </Tooltip>
                <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                >

                    <MenuItem>
                        <NavLink to="/clinica" style={{ textDecoration: 'none' }}>
                            <Typography textAlign="center" sx={{ color: 'black' }}>Minha Clínica</Typography>
                        </NavLink>
                    </MenuItem>

                    <MenuItem>
                        <NavLink to="/financeiro" style={{ textDecoration: 'none' }}>
                            <Typography textAlign="center" sx={{ color: 'black' }}>Financeiro</Typography>
                        </NavLink>
                    </MenuItem>

                    <MenuItem>
                        <NavLink to="/usuarios" style={{ textDecoration: 'none' }}>
                            <Typography textAlign="center" sx={{ color: 'black' }}>Usuários</Typography>
                        </NavLink>
                    </MenuItem>

                    <MenuItem>
                        <NavLink to="/plano" style={{ textDecoration: 'none' }}>
                            <Typography textAlign="center" sx={{ color: 'black' }}>Meu Plano</Typography>
                        </NavLink>
                    </MenuItem>

                    <MenuItem>
                        <NavLink to="/" style={{ textDecoration: 'none' }}>
                            <Typography textAlign="center" sx={{ color: 'black' }} >Sair</Typography>
                        </NavLink>
                    </MenuItem>

                </Menu>
            </Box>
        </>
    );
}

export default Gear;