import React from "react";


const NotFoundPublic = () => {
    return (
        <>
            not found page 
        </>
    );
}


export default NotFoundPublic;