import { useState } from 'react'
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { mainListItems } from '../../../components/menu/Menu';
import Gear from '../../../components/gear/Gear';
import '@fontsource/roboto/400.css';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import { NavLink } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const defaultTheme = createTheme();

const Titulo = "Grupos";
const drawerWidth = 220;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

export default function Grupos() {

    const [open, setOpen] = useState(true);
    const [openModalGrupo, setOpenModalGrupo] = useState(false);
    const [tituloPagina] = useState('Adicionar Grupo')
    const toggleDrawer = () => setOpen(!open);
    const handleOpenModalGrupo = () => setOpenModalGrupo(true);
    const handleCloseModalGrupo = () => setOpenModalGrupo(false);


    //Inicio do Modal Grupos 

    const ModalGrupo = () => {

        const style = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            bgcolor: 'background.paper',
            ///border: '2px solid #000',
            boxShadow: 24,
            p: 4,
        };

        return (
            <>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={openModalGrupo}
                    onClose={handleCloseModalGrupo}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                        backdrop: {
                            timeout: 500,
                        },
                    }}
                >
                    <Fade in={openModalGrupo}>
                        <Box sx={style}>
                            <Grid container spacing={2} justifyContent={'center'}>

                                <Grid item xs={12} md={12} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <MilitaryTechIcon />
                                    <Typography variant="h6">
                                        {tituloPagina}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField fullWidth id="nome_grupo" label="Nome Grupo" variant="outlined" />
                                </Grid>

                                <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                    <Button onClick={handleCloseModalGrupo} variant='contained' color='warning' startIcon={<CancelIcon />} >Cancelar</Button>
                                </Grid>
                                <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button variant='contained' color='primary' endIcon={<SaveIcon />} >Salvar</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Fade>
                </Modal>
            </>
        );
    }


    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#1976d3',
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    function createData(nome) {
        return { nome };
    }

    const rows = [
        createData('Mensalistas'),
        createData('idosos'),
        createData('Crianças'),
        createData('Convênios'),
        createData('Alto Riscos'),
        createData('Crianças'),
        createData('Convênios'),
        createData('Alto Riscos'),
        createData('Crianças'),
        createData('Convênios'),
        createData('Alto Riscos'),
    ];


    return (
        <ThemeProvider theme={defaultTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="absolute" open={open}>
                    <Toolbar
                        sx={{
                            pr: '24px', // keep right padding when drawer closed
                        }}
                    >
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer}
                            sx={{
                                marginRight: '36px',
                                ...(open && { display: 'none' }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            sx={{ flexGrow: 1 }}
                        >
                            {Titulo}
                        </Typography>
                        <IconButton color="inherit">
                            <Gear />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={open}>
                    <Toolbar
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            px: [1],
                        }}
                    >
                        <IconButton onClick={toggleDrawer}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </Toolbar>

                    <Divider />
                    <List component="nav">
                        {mainListItems}
                    </List>
                </Drawer>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar />
                    <Container maxWidth={false} sx={{ mt: 4, mb: 4, width: '100%', height: '100vh' }}>
                        <Paper sx={{ p: 3, display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                    <Button component={NavLink} to='/pacientes' variant='contained' color='secondary' startIcon={<KeyboardReturnIcon />}></Button>
                                </Grid>
                                <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button onClick={handleOpenModalGrupo} variant='contained' color='primary' startIcon={<AddIcon />} >Novo grupo</Button>
                                </Grid>
                                <Grid item xs={12} md={9} sx={{ mt: 4 }} >
                                    <TextField fullWidth id="busca" label="Buscar grupo" variant="outlined" />
                                </Grid>
                                <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
                                    <Button fullWidth variant='contained' color='success' startIcon={<SearchIcon />} >Buscar</Button>
                                </Grid>
                                <Grid item xs={12} sx={{ marginTop: '40px' }}>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell >Nome</StyledTableCell>
                                                    <StyledTableCell >Editar</StyledTableCell>
                                                    <StyledTableCell >Deletar</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rows.map((row) => (
                                                    <StyledTableRow key={row.name}>
                                                        <StyledTableCell >{row.nome}</StyledTableCell>
                                                        <StyledTableCell >
                                                            <IconButton aria-label="delete" disabled color="primary">
                                                                <EditIcon color='warning' />
                                                            </IconButton>
                                                        </StyledTableCell>
                                                        <StyledTableCell >
                                                            <IconButton aria-label="delete" disabled color="primary">
                                                                <DeleteIcon color='error' />
                                                            </IconButton>
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </Paper>
                        <ModalGrupo />
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    );
}
