import * as React from 'react';
import { useState } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { mainListItems } from '../../../components/menu/Menu';
import Gear from '../../../components/gear/Gear';
import '@fontsource/roboto/400.css';
import TextField from '@mui/material/TextField';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import { NavLink } from 'react-router-dom';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';




const defaultTheme = createTheme();

const Titulo = "Pacientes";
const drawerWidth = 220;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);
export default function Pacientes() {


    const [open, setOpen] = useState(true);
    const [openModalPaciente, setOpenModalPaciente] = useState(false);
    const [value] = useState(dayjs());
    const [tituloPagina] = useState('Adicionar novo Paciente')

    const toggleDrawer = () => setOpen(!open);
    const handleOpenModalPaciente = () => setOpenModalPaciente(true);
    const handleCloseModalPaciente = () => setOpenModalPaciente(false);

    const handleDataNascimento = (value) => {
        let data = value;
        console.log('data de nascimento ', data)
    }

    const ModalPaciente = () => {

        const style = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 1200,
            bgcolor: 'background.paper',
            ///border: '2px solid #000',
            boxShadow: 24,
            p: 4,
        };




        const genero = [
            {
                value: '0',
                label: 'Masculino',
            },
            {
                value: '1',
                label: 'Feminino',
            },

        ];

        const conheceu = [
            {
                value: '0',
                label: 'Internet',
            },
            {
                value: '1',
                label: 'Jornais',
            },
            {
                value: '3',
                label: 'Instagran',
            },
            {
                value: '4',
                label: 'Facebook',
            },

        ];

        const grupo = [
            {
                value: '0',
                label: 'Mensalistas',
            },
            {
                value: '1',
                label: 'Idosos',
            },
            {
                value: '3',
                label: 'Convênio',
            },
            {
                value: '4',
                label: 'Crianças',
            },
        ];

        return (
            <>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={openModalPaciente}
                    onClose={handleCloseModalPaciente}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                        backdrop: {
                            timeout: 500,
                        },
                    }}
                >
                    <Fade in={openModalPaciente}>
                        <Box sx={style}>
                            <Grid container spacing={2} justifyContent={'center'}>
                                <Grid item xs={12} md={12} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <PersonAddIcon />
                                    <Typography variant="h6">
                                        {tituloPagina}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={4} >
                                    <TextField
                                        fullWidth
                                        id="grupo"
                                        select
                                        label="Grupo"
                                        defaultValue="0"
                                    >
                                        {grupo.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} md={3} >
                                    <TextField
                                        fullWidth
                                        id="genero"
                                        select
                                        label="Gênero"
                                        defaultValue="0"
                                    >
                                        {genero.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} md={3} >
                                    <TextField
                                        fullWidth
                                        id="conheceu"
                                        select
                                        label="Como nos conheceu?"
                                        defaultValue="0"
                                    >
                                        {conheceu.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} md={2} >
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                                        <DesktopDatePicker
                                            label="Data de Nascimento"
                                            value={value}
                                            onChange={e => handleDataNascimento(e)}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField fullWidth id="nome" label="Nome" variant="outlined" />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <TextField fullWidth id="cpf" label="CPF" variant="outlined" />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField fullWidth id="calular" label="Celular" variant="outlined" />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField fullWidth id="telefone" label="Telefone" variant="outlined" />
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <TextField fullWidth id="email" label="Email" variant="outlined" />
                                </Grid>

                                <Grid item xs={12} md={2}>
                                    <TextField fullWidth id="cep" label="CEP" variant="outlined" />
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <TextField fullWidth id="rua" label="Rua" variant="outlined" />
                                </Grid>

                                <Grid item xs={12} md={2}>
                                    <TextField fullWidth id="numero" label="Número" variant="outlined" />
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <TextField fullWidth id="bairro" label="Bairro" variant="outlined" />
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <TextField fullWidth id="cidade" label="Cidade" variant="outlined" />
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <TextField fullWidth id="estado" label="Estado" variant="outlined" />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField fullWidth id="referencia" label="Referência" variant="outlined" />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField fullWidth id="complemento" label="Complemento" variant="outlined" />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="outlined-multiline-static"
                                        fullWidth
                                        label="Observações"
                                        multiline
                                        rows={4}
                                        placeholder='Digite informações importantes sobre o paciente'
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                    <Button onClick={handleCloseModalPaciente} variant='contained' color='warning' startIcon={<CancelIcon />} >Cancelar</Button>
                                </Grid>
                                <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button variant='contained' color='primary' onClick={handleOpenModalPaciente} endIcon={<SaveIcon />} >Salvar</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Fade>
                </Modal>
            </>
        );
    }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#1976d3',
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    function createData(nome) {
        return { nome };
    }

    const rows = [
        createData('Mensalistas'),
        createData('idosos'),
        createData('Crianças'),
        createData('Convênios'),
        createData('Alto Riscos'),
        createData('Crianças'),
        createData('Convênios'),
        createData('Alto Riscos'),
        createData('Crianças'),
        createData('Convênios'),
        createData('Alto Riscos'),
    ];

    const handleClick = () => {
        console.log('teste')
    }

    return (
        <ThemeProvider theme={defaultTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="absolute" open={open}>
                    <Toolbar
                        sx={{
                            pr: '24px',
                        }}
                    >
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer}
                            sx={{
                                marginRight: '36px',
                                ...(open && { display: 'none' }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            sx={{ flexGrow: 1 }}
                        >
                            {Titulo}
                        </Typography>
                        <IconButton color="inherit">
                            <Gear />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={open}>
                    <Toolbar
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            px: [1],
                        }}
                    >
                        <IconButton onClick={toggleDrawer}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </Toolbar>
                    <Divider />
                    <List component="nav">
                        {mainListItems}
                    </List>
                </Drawer>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar />
                    <Container maxWidth={false} sx={{ mt: 4, mb: 4, width: '100%', height: '100vh' }}>
                        <Paper sx={{ p: 3, display: 'flex', flexDirection: 'column', width: '100%' }}>
                            
                            <Grid container spacing={2} justifyContent={'right'}>
                                <Grid item >
                                    <Button variant='contained' onClick={handleOpenModalPaciente} startIcon={<PersonAddIcon />} >Novo Paciente</Button>
                                </Grid>
                                <Grid item >
                                    <Button component={NavLink} to='/grupos' variant='contained' color='secondary' onClick={handleOpenModalPaciente} startIcon={<MilitaryTechIcon />} >Grupos</Button>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={12} md={9} sx={{ mt: 4 }} >
                                    <TextField fullWidth id="busca" label="Buscar paciente por Nome ou ID." variant="outlined" />
                                </Grid>
                                <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
                                    <Button fullWidth variant='contained' color='success' startIcon={<SearchIcon />} >Buscar</Button>
                                </Grid>
                                <Grid item xs={12} sx={{ marginTop: '40px' }}>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell >Nome</StyledTableCell>
                                                    <StyledTableCell >Editar</StyledTableCell>
                                                    <StyledTableCell >Deletar</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rows.map((row) => (
                                                    <StyledTableRow key={row.name}>
                                                        <StyledTableCell >{row.nome}</StyledTableCell>
                                                        <StyledTableCell >
                                                            <IconButton aria-label="delete" onClick={handleClick}disabled color="primary">
                                                                <EditIcon color='warning' />
                                                            </IconButton>
                                                        </StyledTableCell>
                                                        <StyledTableCell >
                                                            <IconButton aria-label="delete" disabled color="primary">
                                                                <DeleteIcon color='error' />
                                                            </IconButton>
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </Paper>
                        <ModalPaciente />
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    );
}
