import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Login from '../pages/public/login/Login';
import NotFoundPublic from '../pages/public/error/NotFoundPublic';

import Agenda from '../pages/private/agenda/Agenda';
import Consultorio from '../pages/private/consultorio/Consultorio';
import Dentista from '../pages/private/dentista/Dentista';
import Orcamento from '../pages/private/orcamento/Orcamento';
import Pacientes from '../pages/private/paciente/Pacientes';
import Painel from '../pages/private/painel/Painel';
import Procedimento from '../pages/private/procedimento/Procedimento';
import Produtos from '../pages/private/produto/Produto';
import Recepcao from '../pages/private/recepcao/Recepcao';
import Clinica from '../pages/private/clinica/Minha_Clinica';
import Financeiro from '../pages/private/financeiro/Financeiro';
import Usuarios from '../pages/private/usuarios/Usuarios';
import Plano from '../pages/private/plano/Plano';
import Grupos from '../pages/private/paciente/Grupos';
//import ProtectedRoute from './ProtectedRoute';

const Rotas = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route path="/agenda" element={<Agenda />} />
        <Route path="/consultorio" element={<Consultorio />} />
        <Route path="/dentistas" element={<Dentista />} />
        <Route path="/orcamentos" element={<Orcamento />} />
        <Route path="/pacientes" element={<Pacientes />} />
        <Route path="/painel" element={<Painel />} />
        <Route path="/procedimentos" element={<Procedimento />} />
        <Route path="/produtos" element={<Produtos />} />
        <Route path="/recepcao" element={<Recepcao />} />
        <Route path="/clinica" element={<Clinica />} />
        <Route path="/financeiro" element={<Financeiro />} />
        <Route path="/usuarios" element={<Usuarios />} />
        <Route path="/plano" element={<Plano />} />
        <Route path="/grupos" element={<Grupos />} />
        <Route path="*" element={<NotFoundPublic />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Rotas;

// import React from 'react';
// import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import Login from '../pages/login/Login';
// import Dashboard from '../pages/dashboard/Dashboard';
// import Recepcao from '../pages/recepcao/Recepcao';
// import Produtos from '../pages/produtos/Produtos';
// import Procedimentos from '../pages/procedimentos/Procedimentos';
// import Pacientes from '../pages/pacientes/Pacientes';
// import Orcamentos from '../pages/orcamentos/Orcamentos';
// import Dentistas from '../pages/dentistas/Dentistas';
// import NovaConta from '../pages/conta/NovaConta';
// import RecuperaConta from '../pages/conta/RecuperaConta';
// import ValidaConta from '../pages/conta/ValidaConta';
// import NotFound from '../pages/notfound/NotFound';
// import ProtectedRoute from './ProtectedRoute';

// const Rotas = () => {
//   return (
//     <BrowserRouter>
//       <Routes>
//         <Route exact path="/" element={<Login />} />
//         <Route path="/dashboard" element={<ProtectedRoute element={Dashboard} />} />
//         <Route path="/recepcao" element={<ProtectedRoute element={Recepcao} />} />
//         <Route path="/produtos" element={<ProtectedRoute element={Produtos} />} />
//         <Route path="/procedimentos" element={<ProtectedRoute element={Procedimentos} />} />
//         <Route path="/pacientes" element={<ProtectedRoute element={Pacientes} />} />
//         <Route path="/orcamentos" element={<ProtectedRoute element={Orcamentos} />} />
//         <Route path="/dentistas" element={<ProtectedRoute element={Dentistas} />} />
//         <Route path="/conta/nova_conta" element={<NovaConta />} />
//         <Route path="/conta/recupera_conta" element={<RecuperaConta />} />
//         <Route path="/conta/valida_conta" element={<ValidaConta />} />
//         <Route path="*" element={<NotFound />} />
//       </Routes>
//     </BrowserRouter>
//   );
// };

// export default Rotas;

