import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FreeBreakfastIcon from '@mui/icons-material/FreeBreakfast';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import PeopleIcon from '@mui/icons-material/People';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CategoryIcon from '@mui/icons-material/Category';
import MasksIcon from '@mui/icons-material/Masks';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import CalculateIcon from '@mui/icons-material/Calculate';
import { NavLink } from 'react-router-dom';

export const mainListItems = (
  <React.Fragment>

    <ListItemButton component={NavLink} to="/painel">
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Painel" />
    </ListItemButton>

    <ListItemButton component={NavLink} to="/recepcao">
      <ListItemIcon>
        <FreeBreakfastIcon />
      </ListItemIcon>
      <ListItemText primary="Recepção" />
    </ListItemButton>

    <ListItemButton component={NavLink} to="/consultorio">
      <ListItemIcon>
        <VaccinesIcon />
      </ListItemIcon>
      <ListItemText primary="Consultório" />
    </ListItemButton>

    <ListItemButton component={NavLink} to="/pacientes">
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Pacientes" />
    </ListItemButton>

    <ListItemButton component={NavLink} to="/agenda">
      <ListItemIcon>
        <CalendarMonthIcon />
      </ListItemIcon>
      <ListItemText primary="Agenda" />
    </ListItemButton>

    
    <ListItemButton component={NavLink} to="/produtos">
      <ListItemIcon>
        <CategoryIcon />
      </ListItemIcon>
      <ListItemText primary="Produtos" />
    </ListItemButton>

    <ListItemButton component={NavLink} to="/dentistas">
      <ListItemIcon>
        <MasksIcon />
      </ListItemIcon>
      <ListItemText primary="Dentistas" />
    </ListItemButton>

    <ListItemButton component={NavLink} to="/procedimentos">
      <ListItemIcon>
        <MedicalInformationIcon />
      </ListItemIcon>
      <ListItemText primary="Procedimentos" />
    </ListItemButton>

    <ListItemButton component={NavLink} to="/orcamentos"> 
      <ListItemIcon>
        <CalculateIcon />
      </ListItemIcon>
      <ListItemText primary="Orçamentos" />
    </ListItemButton>


  </React.Fragment>
);
